/* eslint-disable max-len */
import _isEqual from 'lodash/isEqual';
import React, { useMemo, useState, useCallback, useContext } from 'react';
import { Grid, Container, Stack, Spacer, Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TableSortLabel, Tooltip } from '@mtb/ui';
import { useTranslation } from 'react-i18next';
import Deployments from '../api/deployments';
import { sortHelper } from '../utils/model-utils';
import { AddModel, ArrowDownIcon } from '../images';
import ModelCard from './ModelCard';
import AddChallengerDialog from './AddChallengerDialog';
import ValidationDialog from './ValidationDialog';
import { Scopes, requireScope } from '../utils/scopes';
import ChevronPager from './ChevronPager';
import './DeploymentModels.scss';
import { ScopeContext, SettingsContext } from '../utils/context';
import { toLocalizedString, toLocalizedArray } from '../utils/locales';
import { InputTypes } from './Inputs';

const MAX_ROWS_PER_PAGE = 20;

const TooltipCell = ({ row, label, cellStyle }) => {
  return (
    <TableCell
      className="ellipses"
      data-testid={`cell-${label}`}
      sx={cellStyle[label] ?? {}}>
      <Tooltip
        disableFocusListener={!row[label]}
        disableHoverListener={!row[label]}
        title={row[label] ?? ''}>
        <span>{row[label] ?? ''}</span>
      </Tooltip>
    </TableCell>
  );
};

const EnhancedTable = ({
  data,
  labels,
  tableName,
  sortableHeader = false,
  cellStyle = {},
  sortProperty,
  sortAsc,
  update,
  currentPage = 1 }) => {
  const [t] = useTranslation();
  const pageChanged = (value) => update({ modelVariablesPage: value });

  const { pageMinIndex, pageMaxIndex } = useMemo(() => {
    return {
      pageMinIndex: (currentPage - 1) * MAX_ROWS_PER_PAGE,
      pageMaxIndex: currentPage * MAX_ROWS_PER_PAGE
    };
  }, [currentPage]);

  const sortFunc = useCallback((a, b) => {
    // If value is undefined always sort it last in list
    return a[sortProperty] === undefined ? 1 :
      b[sortProperty] === undefined ? -1 :
        sortHelper(a[sortProperty], b[sortProperty], sortAsc);
  }, [sortAsc, sortProperty]);

  const rows = useMemo(() => {
    return data.toSorted(sortFunc);
  }, [data, sortFunc]);


  const toggleSort = useCallback(property => {
    const newOrder = sortProperty === property ? !sortAsc : true;
    update({ modelVariablesSortProperty: property, modelVariablesSortAscending: newOrder });
  }, [sortProperty, sortAsc, update]);


  return <div className='table-wrapper'>
    <Box sx={{ display: 'flex' }}>
      <h4>{tableName}</h4>
      <Spacer />
      {rows?.length > MAX_ROWS_PER_PAGE &&
      <ChevronPager
        currentPage={currentPage}
        pageSize={MAX_ROWS_PER_PAGE}
        totalRows={rows.length}
        onPageChange={pageChanged} />}
    </Box>
    <TableContainer>
      <Table
        className="model-variable-table"
        size='small'>
        <TableHead>
          <TableRow hover={false}>
            {labels.map(label =>
              <TableCell
                key={`header-${label}`}
                data-testid={`header-${label}`}
                sortDirection={sortAsc ? 'asc' : 'desc'}
                sx={{ ...(cellStyle[label] ?? {}) }}>
                {sortableHeader ?
                  <TableSortLabel
                    active={sortProperty === label}
                    className='sortable-label'
                    direction={sortAsc ? 'asc' : 'desc'}
                    hideSortIcon={true}
                    Icon={ArrowDownIcon}
                    onClick={() => toggleSort(label)}>
                    {t(label)}
                  </TableSortLabel> :
                  t(label)
                }
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows
            .filter((_, i) => i >= pageMinIndex && i < pageMaxIndex)
            .map(row =>
              <TableRow key={`row-${row.name}`}>
                {labels.map(label =>
                  <TooltipCell
                    key={`label-${row.name}-${label}`}
                    cellStyle={cellStyle}
                    label={label}
                    row={row} />
                )}
              </TableRow>
            )}
        </TableBody>
      </Table>
    </TableContainer>
    {rows?.length > MAX_ROWS_PER_PAGE &&
    <ChevronPager
      currentPage={currentPage}
      pageSize={MAX_ROWS_PER_PAGE}
      right
      totalRows={rows.length}
      onPageChange={pageChanged} />}
  </div>;
};

const DeploymentModels = props => {
  const {
    deployment,
    champion,
    challengers,
    setBusy,
    updateDeployment,
    pendingDeploy,
    pendingPause,
    updateUserDeploymentSettings,
    userDeploymentSettings
  } = props;

  const [t] = useTranslation();
  const settings = useContext(SettingsContext);
  const scope = useContext(ScopeContext);
  const [showAddModelDialog, setShowAddModelDialog] = useState(false);
  const { modelVariablesSortProperty, modelVariablesSortAscending, modelVariablesPage } = userDeploymentSettings;

  const createClassesString = useCallback((list, variableType) => {
    const localizedClasses = list?.filter(i => i.toString().length)
      .map(i => variableType === InputTypes.Numeric ? toLocalizedString(i, settings.locale?.regionCode) : i);
    return toLocalizedArray(localizedClasses?.sort(), settings.locale?.regionCode);
  }, [settings.locale?.regionCode]);

  const createModelNameString = useCallback(list => {
    return toLocalizedArray(list, settings.locale?.regionCode);
  }, [settings.locale?.regionCode]);

  const deploymentModels = useMemo(() => [champion, ...challengers], [champion, challengers]);
  const deploymentModelNames = useMemo(() => deploymentModels.map(model => model.name).sort(), [deploymentModels]);

  const schemaModels = useMemo(() => {
    const schema = Object.keys(deployment.schema.predictors).reduce((acc, v) => ({ ...acc, [v]: [] }), {});
    deploymentModels.forEach(model => {
      Object.keys(model.schema.predictors).forEach(v => {
        schema[v]?.push(model.name);
      });
    });
    return schema;
  }, [deployment.schema.predictors, deploymentModels]);

  const response = useMemo(() => {
    const responseData = deployment.schema.response;
    return ({
      ...responseData,
      classes: createClassesString(responseData.classes, responseData.dataType),
    });
  }, [deployment.schema.response, createClassesString]);

  const predictors = useMemo(() => Object.entries(deployment.schema.predictors).map(([variable, { classes, ...properties }]) => ({
    ...properties,
    name   : variable,
    classes: createClassesString(classes, properties.dataType),
    models : !_isEqual([...schemaModels[variable]].sort(), deploymentModelNames) ? createModelNameString(schemaModels[variable]) : t('all'),
  })), [deployment.schema.predictors, schemaModels, deploymentModelNames, createClassesString, createModelNameString, t]);

  return (
    <>
      <Container
        className='models-content'
        maxWidth={false}>
        <Grid container>
          <Grid
            className='models-side-panel'
            item
            xs={3}>
            <h4>{t('models')}</h4>
            <div>{t('deploymentModelsDescription')}</div>
            <div className="model-cards-container">
              {deploymentModels.map((model, i) => (
                <ModelCard
                  key={`${model.id}-${i}`}
                  deployment={deployment}
                  model={model}
                  pendingDeploy={pendingDeploy}
                  pendingPause={pendingPause}
                  setBusy={setBusy}
                  updateDeployment={updateDeployment} />
              ))}
            </div>
            {requireScope(scope, [Scopes.Deployments]) &&
              <div
                className='add-model clickable'
                onClick={() => {
                  setShowAddModelDialog(true);
                }}>
                <AddModel className='add-icon' />
                <div>{t('addModel')}</div>
              </div>}
          </Grid>
          <Grid
            item
            xs>
            <Stack spacing={3}>
              <Box>
                <h4>{t('variables')}</h4>
                <div className='description'>{t('deploymentVariablesDescription')}</div>
              </Box>
              <Box>
                <EnhancedTable
                  cellStyle={{
                    'name'    : { width: '15%' },
                    'type'    : { width: '15%' },
                    'dataType': { width: '15%' },
                    'classes' : { width: 'auto' },
                  }}
                  data={[response]}
                  labels={['name', 'type', 'dataType', 'classes']}
                  tableName={t('response')} />
              </Box>
              <Box>
                <EnhancedTable
                  cellStyle={{
                    'name'    : { width: '15%' },
                    'type'    : { width: '15%' },
                    'dataType': { width: '15%' },
                    'classes' : { width: '25%' },
                    'models'  : { width: 'auto' }
                  }}
                  currentPage={modelVariablesPage}
                  data={predictors}
                  labels={['name', 'type', 'dataType', 'classes', 'models']}
                  sortableHeader={true}
                  sortAsc={modelVariablesSortAscending}
                  sortProperty={modelVariablesSortProperty}
                  tableName={t('predictors')}
                  update={updateUserDeploymentSettings} />
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Container>
      {showAddModelDialog && <>
        {deploymentModels.length < 3 ?
          <AddChallengerDialog
            addChallenger={async modelId => {
              try {
                setBusy(true);
                await Deployments.addChallenger(deployment.id, modelId);
                await updateDeployment();
                setShowAddModelDialog(false);
              } finally {
                setBusy(false);
              }
            }}
            cancel={() => setShowAddModelDialog(false)}
            champion={champion}
            deployment={deployment} /> :
          <ValidationDialog
            description={t('maximumNumberOfModelsReached')}
            header={t('modelLimit')}
            onSubmit={() => setShowAddModelDialog(false)} />}
      </>}
    </>
  );
};

export default DeploymentModels;