import React, { useState, useRef, useCallback, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button, ClickAwayListener } from '@mtb/ui';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import DataPeriod from './DataPeriod';
import { formatDate } from '../utils/locales';
import { FlagsContext } from '../utils/context';
import { PeriodSettings } from '../utils/model-utils';
import { CalendarIcon } from '../images';

// Sets the UTC offset of a moment object to zero
export const toUtc = date => {
  try {
    return date.utcOffset(0, true);
  } catch (err) {
    return date;
  }
};

const ClickAwayDatePicker = ({ minDate, maxDate, value, onChange }) => {
  const [t] = useTranslation();
  const flags = useContext(FlagsContext);
  const isOpenRef = useRef(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleClickAway = useCallback(() => {
    if (isOpenRef.current) {
      setIsOpen(false);
    }
  }, []);

  return (
    <ClickAwayListener
      onClickAway={handleClickAway}>
      <DatePicker
        className='date-selector'
        components={flags.timezone && {
          OpenPickerIcon: CalendarIcon
        }}
        disableHighlightToday
        inputFormat='YYYY-MM-DD'
        maxDate={maxDate}
        minDate={minDate}
        open={isOpen}
        PopperProps={{
          disablePortal: true
        }}
        renderInput={params => (
          <TextField
            title={!flags.timezone ? t('utcFormatNote') : ''}
            {...params} />
        )}
        value={formatDate(value)}
        onChange={value => onChange(formatDate(toUtc(value)))}
        onClose={() => {
          setIsOpen(false);
          isOpenRef.current = false;
        }}
        onOpen={() => {
          setIsOpen(true);
          isOpenRef.current = true;
        }} />
    </ClickAwayListener>
  );
};

const DateRangeSelector = (props) => {
  const {
    reportSettings,
    setData,
    resetValidation,
    customValidationCallback = () => true,
  } = props;

  const [t] = useTranslation();
  const [pendingStart, setPendingStart] = useState(reportSettings.start);
  const [pendingEnd, setPendingEnd] = useState(reportSettings.end);
  const [pendingPeriod, setPendingPeriod] = useState(reportSettings.period);
  const [hasPendingChanges, setHasPendingChanges] = useState(false);

  useEffect(() => {
    setPendingStart(reportSettings.start);
    setPendingEnd(reportSettings.end);
    setPendingPeriod(reportSettings.period);
  }, [reportSettings.end, reportSettings.start, reportSettings.period]);

  const cancel = () => {
    setPendingStart(reportSettings.start);
    setPendingEnd(reportSettings.end);
    setPendingPeriod(reportSettings.period);
    resetValidation?.();
  };

  const update = async () => {
    const isValid = await customValidationCallback(pendingStart, pendingEnd);
    if (isValid) {
      setData?.(formatDate(pendingStart), formatDate(pendingEnd), pendingPeriod);
    }
  };

  useEffect(() => {
    setHasPendingChanges(
      pendingStart !== reportSettings.start ||
      pendingEnd !== reportSettings.end ||
      pendingPeriod !== reportSettings.period
    );
  }, [t, pendingStart, pendingEnd, pendingPeriod, reportSettings]);

  useEffect(() => {
    if (!hasPendingChanges) {
      resetValidation();
    }
  }, [hasPendingChanges, resetValidation]);

  const setValidStart = date => {
    const newValue = formatDate(date);
    if (newValue > pendingEnd) {
      setPendingStart(newValue);
      setPendingEnd(newValue);
    } else {
      setPendingStart(newValue);
    }
  };

  const setValidEnd = date => {
    const newValue = formatDate(date);
    if (newValue < pendingStart) {
      setPendingStart(newValue);
      setPendingEnd(newValue);
    } else {
      setPendingEnd(newValue);
    }
  };

  return (
    <div className={classNames('date-selector-container', { 'pending-update': hasPendingChanges })}>
      <DataPeriod
        dataPeriod={pendingPeriod}
        excludedOptions={[PeriodSettings.Quarterly]}
        labelKey='period'
        t={t}
        onChange={e => setPendingPeriod(e)} />
      <div className='date-range'>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <div className='date-picker'>
            <label>{t('startDate')}</label>
            <ClickAwayDatePicker
              value={pendingStart}
              onChange={setValidStart} />
            <label>{t('endDate')}</label>
            <ClickAwayDatePicker
              value={pendingEnd}
              onChange={setValidEnd} />
          </div>
        </LocalizationProvider>
      </div>
      {hasPendingChanges && <>
        <Button
          className='button secondary'
          id='date-selector-cancel'
          variant='contained'
          onClick={cancel}>
          {t('cancel')}
        </Button>
        <Button
          className='button'
          id='date-selector-update'
          variant='contained'
          onClick={update}>
          {t('update')}
        </Button>
      </>}
    </div>
  );
};

export default DateRangeSelector;