import moment from 'moment';
import { round } from './model-utils';
import Highcharts from 'highcharts';
import Highstock from 'highcharts/highstock';
import { formatShortLocalDateTime } from './locales';

const BIN_DATE_FORMAT = 'YYYY-MM-DD HH:mm[Z]';

const DATETIME_TICK_LABEL_FORMAT_DAY = '%e-%b';
const DATETIME_TICK_LABEL_FORMAT_MONTH = '%b-%Y';

const DATETIME_TOOLTIP_FORMAT = '%Y-%m-%d %H:%M:%SZ';
const DATETIME_TOOLTIP_FORMAT_TIMEZONE = '%Y-%m-%d %H:%M:%S';

export const getDatetimeTooltipFormat = (timezoneFlag) =>
  timezoneFlag ? DATETIME_TOOLTIP_FORMAT_TIMEZONE : DATETIME_TOOLTIP_FORMAT;

export const getBinDateFormat = (addSeconds = false) =>
  addSeconds ? BIN_DATE_FORMAT.replace('[Z]', ':ss[Z]') : BIN_DATE_FORMAT;

export const getDateTimeLabelFormats = (timezoneFlag) => ({
  millisecond: getDatetimeTooltipFormat(timezoneFlag),
  day        : DATETIME_TICK_LABEL_FORMAT_DAY,
  week       : DATETIME_TICK_LABEL_FORMAT_DAY,
  month      : DATETIME_TICK_LABEL_FORMAT_MONTH
});

export const MILLISECONDS_ONE_HOUR = 3600000;

export const SERIES_COLORS = ['#6497c9', '#e8797a', '#009e25'];

const PROMOTION_LINE_COLOR = '#CCCCCC';

const OPTIMAL_COLUMN_WIDTH = 32;

export const calculateBinInterval = (bins, numPoints) => {
  const { 0: first, [bins.length - 1]: last } = bins;
  return (last - first) / numPoints;
};

const scaleToPercent = value => value === 0 ? null : value * 100;

const calculateTimeSeriesXMin = series => {
  const values = series.map(s => s.data.reduce((prev, current) => {
    return (current.x && current.x < prev) ? current.x : prev;
  }, Number.MAX_VALUE));
  return Math.min(...values.flat());
};

export const calculateTimeSeriesYMax = (values, thresholds) => {
  return Math.max(...values, thresholds.moderate, thresholds.severe);
};

const calculateXAxisMaxCategories = (chartWidth, numCategories) => {
  return Math.min(Math.floor(chartWidth / OPTIMAL_COLUMN_WIDTH), numCategories) - 1;
};

const calculateOverlaidYMax = chart => {
  const yValues = chart.series.filter(s => s.visible).map(s => s.yData).flat();
  return Math.max(...yValues);
};

const isDefined = val => val || val === 0;

const insertTimeSeriesMissings = (data, gapSize) => {
  const timeValues = data.map(d => d[0]);
  const intervals = timeValues.slice(1).map((item, index) => item - timeValues[index]);
  let indicesNeedingGaps = intervals.reduce(
    (indices, interval, i) => interval > gapSize ? [...indices, i + 1] : indices,
    []);
  // Reverse so we can insert "backwards" to avoid changing target positions in array as we go.
  indicesNeedingGaps = indicesNeedingGaps.reverse();
  indicesNeedingGaps.forEach(index => data.splice(index, 0, [null, null]));
  return data;
};

export const getDriftTimeSeriesData = (data) => {
  const timeValues = typeof data.time_values[0] === 'object' ?
    data.time_values.map(bin => bin.start) : data.time_values;
  const yValues = data.psi_values;

  return timeValues.map((time, i) => {
    let point = [moment(time).utc().valueOf(), yValues[i]];
    // If values before and after curr value are undefined/null and the current value exists
    // we need to enable marker to graph a single point
    if (isDefined(yValues[i]) && !isDefined(yValues[i - 1]) && !isDefined(yValues[i + 1])) {
      point = { x: point[0], y: point[1], marker: { enabled: true, radius: 2 } };
    }
    return point;
  });
};

const isChampion = (timestamp, promotionRanges) => {
  for (const range of promotionRanges) {
    const [start, end] = range;
    if ((timestamp >= start || isNaN(start)) && (timestamp < end || isNaN(end))) {
      return true;
    }
  }
  return false;
};

const updateChampionMarkers = (rawData, seriesColor, promotionRanges, gapSize) => {
  const data = insertTimeSeriesMissings(rawData, gapSize);
  data.forEach((point, i) => {
    const championSeries = isChampion(point[0], promotionRanges);
    data[i] = {
      x: point[0],
      y: point[1],
      ...(!championSeries && {
        marker: {
          lineWidth: 1,
          lineColor: seriesColor,
          fillColor: '#fff'
        }
      })
    };
  });
  return data;
};

export const getOverlaidStabilityData = (data, color, promotionRanges, gapSize) => {
  const timeValues = data.time_values;
  const utcData = timeValues.map((time, i) => [moment(time).utc().valueOf(), round(data.data_values[i], 3)]);
  return updateChampionMarkers(utcData, color, promotionRanges, gapSize);
};

export const getTimeSeriesBinTooltip = (tooltip, bins, timezone) => {
  const formatter = value => !!timezone ? formatShortLocalDateTime(value, timezone) :
    moment.utc(value).format(getBinDateFormat());

  const chip = `<span style="color: ${tooltip.series.color}">● </span>`;
  const info = `<b>${tooltip.series.name}</b>: ${Highcharts.numberFormat(round(tooltip.y, 3), -1)}`;

  const index = tooltip.series.data.indexOf(tooltip.point);
  if (index === -1) {
    return tooltip.key; // Promotion tooltip
  }
  const { start, end } = bins[index];
  const interval = start === end ? [formatter(start)] : [formatter(start), formatter(end)];
  return `${interval.join(' - ')}<br>${chip}${info}`;
};

const addLocalOffset = (ts, sodOffset, timezone) => {
  const localDate = moment.tz(Number(ts), timezone);
  // Cannot use .add() function because this does not take into account
  // DST transition, e.g. moment.tz(2024-03-10, 'America/New York').add(5, 'hours')
  // returns 2024-03-10 06:00 instead of 2024-03-10 05:00
  return localDate.hours(localDate.hours() + sodOffset).valueOf();
};

export const getOffsetTickPositions = (tickPositions, tzInfo) => {
  const utcOffset = moment.tz(tzInfo.timezone).utcOffset();
  const sodOffset = ((Number(tzInfo.adjustedOffset) * 60) + utcOffset ?? 0) / 60;
  const newTicks = tickPositions.map(ts => addLocalOffset(ts, sodOffset, tzInfo.timezone));
  newTicks['info'] = tickPositions.info;
  if (Object.keys(tickPositions.info.higherRanks).length) {
    newTicks.info.higherRanks = Object.fromEntries(
      Object.entries(tickPositions.info.higherRanks)
        .map(([ts, val]) => [addLocalOffset(ts, sodOffset), val]));
  }
  return newTicks;
};

export const getHistogramSeries = (series1, series2, series1BinInterval, series2BinInterval) => {
  const series1Data = series1.data.bin_locations.map((bin, i) => [bin, scaleToPercent(series1.data.values[i])]);
  const series2Data = series2.data.bin_locations.map((bin, i) => [bin, scaleToPercent(series2.data.values[i])]);
  const series = [];

  if (series1Data.length) {
    series.push({
      animation : false,
      type      : 'column',
      color     : '#7ba4d7',
      name      : series1.name,
      data      : series1Data,
      pointRange: series1BinInterval
    });
  }
  if (series2Data.length) {
    series.push({
      animation : false,
      type      : 'column',
      color     : '#c43d39',
      name      : series2.name,
      data      : series2Data,
      pointRange: series2BinInterval
    });
  }

  return series;
};

const getCategoricalDataIncludingMissings = (levels, data) => {
  // Insert a count of 0 for any level that is absent from the series data.
  // This will ensure the chart is accurate because both series share
  // a single set of levels.
  return levels.map(bin => {
    const levelIndex = data.bin_locations.indexOf(bin);
    return levelIndex === -1 ? 0 : data.values[levelIndex];
  });
};

const renameMissingLevel = levels => {
  const missingIndex = levels.indexOf('');
  if (missingIndex !== -1) {
    levels.splice(missingIndex, 1, 'Missing');
  }
};

export const getOverlaidCategoricalData = (data1, data2) => {
  renameMissingLevel(data1.bin_locations);
  renameMissingLevel(data2.bin_locations);
  const allLevels = Array.from(new Set([...data1.bin_locations, ...data2.bin_locations]));
  allLevels.sort();

  return {
    levels     : allLevels,
    series1Data: getCategoricalDataIncludingMissings(allLevels, data1).map(v => v * 100),
    series2Data: getCategoricalDataIncludingMissings(allLevels, data2).map(v => v * 100),
  };
};

export const mssScrollbarOptions = {
  enabled              : true,
  height               : 8,
  barBackgroundColor   : '#ccc',
  barBorderRadius      : 5,
  barBorderWidth       : 0,
  buttonBackgroundColor: '#fff',
  buttonArrowColor     : 'transparent',
  buttonBorderWidth    : 0,
  rifleColor           : 'transparent',
  buttonBorderRadius   : 0,
  trackBackgroundColor : '#eee',
  trackBorderWidth     : 0,
  trackBorderRadius    : 20,
  trackBorderColor     : '#eee',
  showFull             : false,
  liveRedraw           : true,
};

// Event handlers

export const onLegendItemClick = (event, chart) => {
  event.preventDefault();
  event.target.setVisible();
  const yMax = calculateOverlaidYMax(chart.chart);
  chart.yAxis.setExtremes(0, yMax);

};

export const onResize = (chart, numCategories) => {
  chart.update({
    xAxis: {
      max: calculateXAxisMaxCategories(chart.chartWidth, numCategories)
    }
  });
};

// Challenger utils

export const getChallengerTooltip = (bins, tooltip, tzInfo) => {
  const formatter = value => tzInfo ?
    formatShortLocalDateTime(value, tzInfo.timezone) :
    moment.utc(value).format(getBinDateFormat());

  const chip = `<span style="color: ${tooltip.series.color}">● </span>`;
  const info = `<b>${tooltip.series.name}</b>: ${Highcharts.numberFormat(round(tooltip.y, 3), -1)}`;

  if (bins?.length) {
    const series = tooltip.series.data.filter(data => data.x !== null);
    const [start, end] = bins[series.indexOf(tooltip.point)];
    const interval = start === end ? [formatter(start)] : [formatter(start), formatter(end)];
    return `${interval.join(' - ')}<br>${chip}${info}`;
  }

  return `${formatter(tooltip.x)}<br>${chip}${info}`;
};

export const hidePromotionHighlights = chart => {
  const disable = line => {
    line.svgElem?.attr('stroke', line.options.color);
    line.svgElem?.attr('dashstyle', 'Dash');
  };
  chart.xAxis.forEach(axis => axis.plotLinesAndBands.forEach(line => disable(line)));
  chart.redraw();
};

const showPromotionTooltip = (t, plotLine, mouseEvent) => {
  const series = plotLine.axis.series[0];
  const chart = series.chart;
  const PointClass = series.pointClass;
  const tooltip = chart.tooltip;
  const label = `${t('newChampion')} <b>${plotLine.options.id}</b><br>`;
  const point = (new PointClass()).init(series, [label, plotLine.options.value]);
  point.color = plotLine.options.hoverColor;
  const normalizedEvent = chart.pointer.normalize(mouseEvent);
  point.tooltipPos = [
    Math.min(normalizedEvent.chartX - chart.plotLeft, chart.plotWidth),
    normalizedEvent.chartY - chart.plotTop
  ];
  tooltip.refresh(point);
  hidePromotionHighlights(chart);
  plotLine.svgElem?.attr('stroke', plotLine.options.hoverColor);
  plotLine.svgElem?.attr('dashstyle', 'Solid');
};

const hidePromotionTooltip = (plotLine, mouseEvent) => {
  const mousePos = mouseEvent.layerX;
  const anchorPos = plotLine.svgElem?.pathArray[0][1];
  if (Math.abs(mousePos - anchorPos) > 10) {
    setTimeout(() => {
      plotLine.svgElem?.attr('stroke', plotLine.options.color);
      plotLine.svgElem?.attr('dashstyle', 'Dash');
    }, 500);
    plotLine.axis.chart.tooltip.hide();
  }
};

export const getAllPromotionsByModelSeries = (t, promotions, series) => {
  const firstDataPoint = Math.min(...series.map(s => s.data[0].x));
  promotions.sort((a, b) => a[1] > b[1] ? 1 : -1);
  return getPromotionPlotLines(t, promotions, firstDataPoint);
};

export const getPromotionPlotLines = (t, promotions, firstDataPoint, overwriteDefault = {}) => {
  const createPromotionPlotLine = (modelName, timestamp) => {
    return {
      id        : modelName,
      value     : new Date(timestamp),
      color     : PROMOTION_LINE_COLOR,
      hoverColor: '#6497c9',
      dashStyle : 'Dash',
      width     : 1,
      events    : {
        mouseover: function(e) {
          showPromotionTooltip(t, this, e);
        },
        mouseout: function(e) {
          hidePromotionTooltip(this, e);
        }
      },
      ...overwriteDefault
    };
  };

  if (!promotions?.length) {
    return [];
  }

  if (!firstDataPoint) {
    return promotions.map(([modelName, timestamp]) =>
      createPromotionPlotLine(modelName, new Date(timestamp)));
  }

  let loggedFirstDataPoint = false;
  const plotLines = [];
  for (let i = 0; i < promotions.length; i++) {
    const [modelName, timestamp] = promotions[i];
    const [, nextPromotionTime] = promotions[i + 1] ?? [undefined, undefined];
    if (loggedFirstDataPoint) {
      plotLines.push(createPromotionPlotLine(modelName, new Date(timestamp)));
    } else if (nextPromotionTime && new Date(nextPromotionTime) > new Date(firstDataPoint)) {
      loggedFirstDataPoint = true;
      plotLines.push(createPromotionPlotLine(modelName, new Date(firstDataPoint)));
    }
  }
  return plotLines;
};

// Force x-axis tick labels to display when data is sparse
export const forceShowXTickLabels = (chart, series, flags) => {
  const tickInterval = chart.xAxis[0].tickInterval;
  const { min, max } = chart.xAxis[0].getExtremes();
  const dataRange = max - min;
  if (tickInterval > dataRange) {
    const xPositions = series.map(s => s.data.map(point => point.x)).flat();
    chart.xAxis[0].setExtremes(Math.min(...xPositions), Math.max(...xPositions));
    chart.xAxis.forEach(axis => axis.update({
      dateTimeLabelFormats: {
        ...getDateTimeLabelFormats(flags.timezone),
        year: getDateTimeLabelFormats(flags.timezone).day
      }
    }));
  }
};

// Offset min x-value relative to length of axis
export const setXAxisExtremes = (chart, series, minPadding = 0.01) => {
  const calculatedXMin = calculateTimeSeriesXMin(series);
  const offset = (chart.xAxis[0].dataMax - calculatedXMin) * minPadding;
  chart.xAxis[1].update({ min: calculatedXMin - offset }, true);
};

export const applyGlobalHighchartOptions = (systemLocale) => {
  // When we use Highcharts.NumberFormat on large numbers, we
  // do not want to show thousands separator so override default here
  const options = {
    decimalPoint : systemLocale.decimalSeparator,
    listSeparator: systemLocale.listSeparator,
    thousandsSep : ''
  };

  const highchartsOptions = Highcharts.getOptions();
  const highstockOptions = Highstock.getOptions();
  Highcharts.setOptions({
    ...highchartsOptions,
    lang: {
      ...highchartsOptions.lang,
      ...options
    }
  });

  Highstock.setOptions({
    ...highstockOptions,
    lang: {
      ...highstockOptions.lang,
      ...options,
    }
  });
};