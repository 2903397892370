import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Highstock from 'highcharts/highstock';
import { round } from '../utils/model-utils';
import {
  barchartExpandedConfig,
  histogramExpandedConfig,
  timeSeriesExpandedConfig,
} from '../utils/chart-options';
import { FlagsContext } from '../utils/context';
import { DefaultSettings } from '../utils/model-utils';

const StatsTable = ({ variable }) => {
  const [t] = useTranslation();
  const baselineData = variable.baseline;
  const productionData = variable.production;

  const array = ['number', 'missings'];
  if (variable.type === 'continuous') {
    array.push('mean', 'stdev', 'min', 'q1', 'median', 'q3', 'max');
  }

  const roundWithExponent = (number, precision) => {
    const exponent = number?.toString().split('e');
    if (exponent?.length > 1) {
      return `${round(exponent[0], precision - 1)}e${exponent[1]}`;
    }
    return round(number, precision);
  };

  return (
    <div className="stats-table">
      <div className="header-row">
        <div><span>{t('data')}</span></div>
        {array.map((tag, id) => <div key={id}><span>{t(tag)}</span></div>)}
      </div>
      <div className="data-row">
        <div><span>{t('baseline')}</span></div>
        {array.map((tag, id) =>
          <div
            key={id}
            title={baselineData[tag] && Highcharts.numberFormat(baselineData[tag], -1)}>
            <span>{baselineData[tag] !== undefined ?
              Highcharts.numberFormat(roundWithExponent(baselineData[tag], 3), -1) : '*'}</span>
          </div>
        )}
      </div>
      <div className="data-row">
        <div><span>{t('production')}</span></div>
        {array.map((tag, id) =>
          <div
            key={id}
            title={Highcharts.numberFormat(productionData[tag], -1)}>
            <span>{productionData[tag] !== undefined ?
              Highcharts.numberFormat(roundWithExponent(productionData[tag], 3), -1) : '*'}</span>
          </div>
        )}
      </div>
    </div>
  );
};

const ExpandedHistogram = ({ variable }) => {
  const [t] = useTranslation();

  const histogramOptions = histogramExpandedConfig(
    t,
    {
      name: t('baseline'),
      data: variable.baseline?.data ?? { bin_locations: [], values: [] }
    },
    {
      name: t('production'),
      data: variable.production?.data ?? { bin_locations: [], values: [] }
    },
    variable.name
  );

  return (
    <div className="graph">
      <HighchartsReact
        highcharts={Highcharts}
        options={histogramOptions} />
    </div>
  );
};

const ExpandedBarchart = ({ variable }) => {
  const [t] = useTranslation();

  const barchartOptions = barchartExpandedConfig(
    t,
    {
      name: t('baseline'),
      data: variable.baseline?.data ?? { bin_locations: [], values: [] }
    },
    {
      name: t('production'),
      data: variable.production?.data ?? { bin_locations: [], values: [] }
    },
    variable.name,
    variable.dataType
  );

  return (
    <div className="graph">
      <HighchartsReact
        highcharts={Highstock}
        options={barchartOptions} />
    </div>
  );
};

const ExpandedTimeSeries = ({ variable, metadata, promotions }) => {
  const [t] = useTranslation();
  const [timeSeriesOptions, setTimeSeriesOptions] = useState();
  const flags = useContext(FlagsContext);

  const time_values = variable.psi_timeseries?.time_values;
  const psi_values = variable.psi_timeseries?.psi_values;

  useEffect(() => {
    setTimeSeriesOptions(timeSeriesExpandedConfig(
      t,
      {
        name: t('driftPSI'),
        data: { time_values: time_values ?? [], psi_values: psi_values ?? [] }
      },
      {
        moderate: metadata.moderate_drift_threshold ?? 0.1,
        severe  : metadata.severe_drift_threshold ?? 0.2,
      },
      promotions,
      flags.timezone ?
        { timezone      : metadata.timezone ?? DefaultSettings.Timezone,
          adjustedOffset: metadata.adjusted_offset ?? 0 } : undefined
    ));
  }, [t, metadata, promotions, time_values, psi_values, flags.timezone]);

  return (
    <div className="graph">
      <HighchartsReact
        highcharts={Highstock}
        options={timeSeriesOptions} />
    </div>
  );
};

export const ContinuousExpandedView = ({ variable, metadata, promotions }) => (
  <div
    className="graph-container"
    id={`${variable.name}-expanded`}>
    <div className="chart-content">
      <ExpandedHistogram variable={variable} />
      <StatsTable variable={variable} />
    </div>
    <div className="chart-content">
      <ExpandedTimeSeries
        metadata={metadata}
        promotions={promotions}
        variable={variable} />
    </div>
  </div>);

export const CategoricalExpandedView = ({ variable, metadata, promotions }) => (
  <div
    className="graph-container"
    id={`${variable.name}-expanded`}>
    <div className="chart-content">
      <ExpandedBarchart variable={variable} />
      <StatsTable variable={variable} />
    </div>
    <div className="chart-content">
      <ExpandedTimeSeries
        metadata={metadata}
        promotions={promotions}
        variable={variable} />
    </div>
  </div>);